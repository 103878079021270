var slickSlider = {
  sliderInit: function() {
    $('.slick-slider').each(function() {

      // Slider Object
      var slider = $(this);

      // Dots variables
      var hasDots = false;

      //Arrows variables
      var hasArrows = false;

      // If slider has dots class, enable navigation dots
      if (slider.hasClass('slider-with-dots')) {
        hasDots = true;
      }

      if (slider.hasClass('slider-with-arrows')) {
        hasArrows = true;
      }

      // Layout
      var layout = slider.data('layout');

      // Autoplay
      var hasAutoplay = slider.data('autoplay');

      // Set responsive layout
      var responsiveLayout = layout == 4 ? 2 : layout == 3 ? 2 : 1;
      
      // Finally init the slider
      slider.slick({
        arrows: hasArrows,
        dots: hasDots,
        autoplay: hasAutoplay,
        autoplaySpeed: 4000,
        slidesToShow: layout,
        slidesToScroll: layout,
        responsive: [
          {
            breakpoint: 769,
            settings: {
              slidesToShow: responsiveLayout,
              slidesToScroll: responsiveLayout
            }
          },
          {
            breakpoint: 481,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
        ]
      });
    });
  }
};

$(document).ready(function() {
  if ($('.slick-slider').length) {
    slickSlider.sliderInit();
  }
});
